<template>
  <div class="invitePage">
    <div class="app h5MaxWidth">
      <header class="isScrollTo noColor">
        <div class="header h5MaxWidth clear display alignCenter">
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">Incite</h2>
          <div class="navskip" id="sideMenu"></div>
        </div>
      </header>
      <div class="subject h5MaxWidth">
        <div class="img"><img src="./../images/invite/01.png" alt="" /></div>
        <div class="initiateArea">
          <div class="slogan">
            <h3></h3>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="inviteRule">
          <img id="QRCLogo" src="./../images/lodingIcon.svg" hidden />
          <div class="upgradeBonus ratioAward radius15PX">
            <div class="titleBar display alignCenter justifyDetween">
              <div>
                <h3>VIP upgrade Bonus</h3>
              </div>
            </div>
            <div class="radius7PX display alignCenter">
              <div class="img">
                <img src="./../images/invite/02.png" alt="" />
              </div>
              <div class="rewardValue">
                <h2>100</h2>
                <p>Rewards</p>
              </div>
            </div>
            <div class="hint radius7PX flex1 display alignCenter">
              <p>
                If your friend upgrades to VIP1 or above, you will receive 100
                rewards
              </p>
            </div>
          </div>
          <div class="cashReturn clear radius7PX">
            <div class="myTeam">
              <div class="titleBar display alignCenter justifyDetween">
                <div>
                  <h3>Team rank general</h3>
                </div>
              </div>
              <div
                class="one team display alignEnd radius7PX"
                onclick="showListdetails(1)"
              >
                <div class="more_Dots display alignCenter">
                  <i class="iconfont icon-menu-dots"></i>
                </div>
                <div class="rankIcon">
                  <img src="./../images/invite/lv1.svg" alt="" />
                </div>
                <div class="standings flex1 display alignEnd">
                  <div class="flex1">
                    <p id="level1Count">0</p>
                    <span>Quantity</span>
                  </div>
                  <div class="flex1">
                    <p id="level1Commission"><em class="currency">₹</em>0.00</p>
                    <span>Commission</span>
                  </div>
                  <div class="flex1">
                    <p id="commissionLevel1">35.00%</p>
                    <span>Reward ratio</span>
                  </div>
                </div>
              </div>
              <div
                class="two team display alignEnd radius7PX"
                onclick="showListdetails(2)"
              >
                <div class="more_Dots display alignCenter">
                  <i class="iconfont icon-menu-dots"></i>
                </div>
                <div class="rankIcon">
                  <img src="./../images/invite/lv2.svg" alt="" />
                </div>
                <div class="standings flex1 display alignEnd">
                  <div class="flex1">
                    <p id="level2Count">0</p>
                    <span>Quantity</span>
                  </div>
                  <div class="flex1">
                    <p id="level2Commission"><em class="currency">₹</em>0.00</p>
                    <span>Commission</span>
                  </div>
                  <div class="flex1">
                    <p id="commissionLevel2">4.00%</p>
                    <span>Reward ratio</span>
                  </div>
                </div>
              </div>
              <div
                class="two team display alignEnd radius7PX"
                onclick="showListdetails(3)"
              >
                <div class="more_Dots display alignCenter">
                  <i class="iconfont icon-menu-dots"></i>
                </div>
                <div class="rankIcon">
                  <img src="./../images/invite/lv3.svg" alt="" />
                </div>
                <div class="standings flex1 display alignEnd">
                  <div class="flex1">
                    <p id="level3Count">0</p>
                    <span>Quantity</span>
                  </div>
                  <div class="flex1">
                    <p id="level3Commission"><em class="currency">₹</em>0.00</p>
                    <span>Commission</span>
                  </div>
                  <div class="flex1">
                    <p id="commissionLevel3">1.00%</p>
                    <span>Reward ratio</span>
                  </div>
                </div>
              </div>
              <div class="hint radius7PX" v-html="info.rule.content"></div>
            </div>
          </div>
        </div>
        <div class="fixedArea h5MaxWidth radius151500PX">
          <div class="explain">
            <p>
              You can copy the link to share with friends or QR code to send
              friends to participate in the investment invitation
            </p>
          </div>
          <div class="actionBar display alignCenter alignStretch">
            <div
              class="copyBtn clickBtn radius7PX flex4 display alignCenter justifyCenter"
              id="copylink"
              data-clipboard-text=""
              @click="getLink()"
            >
              Copy Invite Link
            </div>
            <div
              class="getQRcodeBtn clickBtn radius7PX flex3 display alignCenter justifyCenter"
              id="getQRC"
              @click="getQRC()"
            >
              QRCode
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showPop"
      position="top"
      :style="{ background: 'none', top: '20%' }"
    >
      <div class="promptMain et-02s radius15PX">
        <div class="promptTitleBar display alignCenter justifyDetween"></div>
        <div class="promptCont">
          <div class="copiedSuccessful">
            <div class="successTip explain">
              <h3>Copied successfully</h3>
              <p>Paste and share with your invitees!</p>
            </div>
            <div class="inviteUrl">
              <h4>Copied to URL</h4>
              <p>{{ info.info.inviteLink }}</p>
            </div>
          </div>
          <div class="actionBar display alignCenter alignStretch">
            <div
              class="okCancelBtn clickBtn_S flex1 display alignCenter justifyCenter"
              @click="showPop = false"
            >
              OK
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showPop2"
      position="top"
      :style="{ background: 'none', top: '20%' }"
    >
      <div class="promptMain et-02s radius15PX">
        <div class="promptTitleBar display alignCenter justifyDetween">
          <div
            class="roundOff radius50P display alignCenter justifyCenter"
            @click="showPop2 = false"
          >
            <i class="icon-cross iconfont"></i>
          </div>
        </div>
        <div class="promptCont">
          <div id="invitationQRCode">
            <img
              :src="codeUrl"
              alt=""
              style="width: 300px; display: block; margin: 0 auto"
            />
          </div>
          <div class="explain">
            <p>Long press the QR code to save the share invitation</p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import QRCode from "qrcode";
export default {
  data() {
    return {
      codeUrl: "",
      showPop: false,
      showPop2: false,
      userInfo: {},
      info: {
        info: {
          inviteLink: "",
        },
        rule: {
          content: "",
        },
      },
    };
  },
  methods: {
    getLink() {
      this.copyText(this.info.info.inviteLink);
      this.showPop = true;
    },
    getQRC() {
      QRCode.toDataURL(this.info.info.inviteLink, {
        color: {
          dark: "#000",
          light: "#fff",
        },
      }).then((url) => {
        // 获取到url后即可在页面使用--二维码图片
        this.codeUrl = url;
        this.showPop2 = true;
      });
    },
  },
  mounted() {
    this.userInfo = this.getItem("userInfo");
    this.getAjax(
      "api/share/index",
      {
        uid: this.userInfo.uid,
      },
      "post"
    ).then((res) => {
      this.info = res.data;
    });
  },
};
</script>

<style scoped lang="less">
@charset "utf-8";

/* --- */

.invitePage {
  margin: 0 auto 200px;
}

.invitePage header .header {
  background-color: var(--a_1);
}

.invitePage .main {
  margin: 0;
  padding: 280px 0 0;
  position: relative;
}

.invitePage .titleBar {
  padding: 0 0 10px;
  border-width: 0;
}

.invitePage .titleBar h3 {
  font-size: 22px;
  color: var(--black1);
}

.invitePage .subject {
  top: 0;
  left: 0;
  right: 0;
  padding: 60px 20px 80px;
  background-color: var(--a_1);
  position: fixed;
  z-index: 0;
}

.invitePage .recordCard {
  padding: 10px 20px;
  overflow: hidden;
  background-color: #fff;
  -moz-box-shadow: 0px -20px 20px 0px rgba(0, 40, 14, 0.1);
  -webkit-box-shadow: 0px -20px 20px 0px rgba(0, 40, 14, 0.1);
  box-shadow: 0px -20px 20px 0px rgba(0, 40, 14, 0.1);
  z-index: 1;
}

.invitePage .recordCard .shade {
  opacity: 0.3;
  z-index: 0;
}

.invitePage .recordCard ul {
  position: relative;
  z-index: 1;
}

.invitePage .recordCard ul li {
  padding: 10px 0;
}

.invitePage .recordCard ul li > div {
  height: 100%;
}

.invitePage .recordCard ul li p {
  line-height: 1;
  font-size: 20px;
  font-weight: 700;
  color: var(--black1);
}

.invitePage .recordCard ul li span {
  font-size: 12px;
  font-weight: 300;
  color: var(--black2);
}

.invitePage .recordCard ul li.award p {
  font-size: 30px;
}

.invitePage .inviteRule {
  margin: 5px;
  position: relative;
  z-index: 99;
}

.invitePage .inviteRule > div {
  margin: 10px 0;
}

.invitePage .shareUrl {
  margin: 0 30px;
  padding: 10px 20px;
  font-size: 14px;
}

.invitePage .shareUrl p {
  font-size: 14px;
  font-weight: 300;
  color: var(--black3);
}

.invitePage .shareUrl p span {
  color: var(--black2);
}

.invitePage .actionBar {
  width: initial;
}

.invitePage .actionBar .goTeamBtn {
  color: #fff;
  background-color: var(--a_1);
}

.invitePage .actionBar .copyBtn {
  max-width: 280px;
  font-size: 16px;
  color: #fff;
  background: var(--b_1);
  background: linear-gradient(90deg, var(--b_1) 41%, var(--b_2) 100%);
  -moz-box-shadow: 0 -3px 30px 1px rgba(80, 35, 0, 0.1);
  -webkit-box-shadow: 0 -3px 30px 1px rgba(80, 35, 0, 0.1);
  box-shadow: 0 -3px 30px 1px rgba(80, 35, 0, 0.1);
}

.invitePage .actionBar .getQRcodeBtn {
  background: var(--a_1);
}

/* --- */
.invitePage .subject .initiateArea {
  top: 90px;
  left: 0;
  right: 0;
  padding: 20px;
  position: absolute;
  z-index: 9;
}

.invitePage .subject .slogan {
  text-align: center;
}

.invitePage .subject .slogan h3 {
  line-height: 1;
  margin: 0 0 10px;
  font-size: 40px;
  font-weight: 700;
  color: #393939;
}

.invitePage .subject .slogan p {
  top: -10px;
  line-height: 1;
  font-size: 26px;
  color: #fff;
  opacity: 0.8;
  position: relative;
}

.invitePage .subject .img img {
  width: 100%;
}

.invitePage .inviteRule .cashReturn .actionBar {
  background-color: transparent;
}

.invitePage .inviteRule .cashReturn .img {
  width: 120px;
}

.invitePage .inviteRule .cashReturn .img img {
  width: 100%;
}

.invitePage .inviteRule .hint {
  padding: 20px 0;
}

.invitePage .inviteRule .hint p {
  font-size: 18px;
  color: var(--black1);
}

.invitePage .recordCard,
.invitePage .upgradeBonus,
.invitePage .cashReturn {
  padding: 20px;
  position: relative;
  background-color: #fff;
  z-index: 9;
}

.invitePage .recordCard,
.invitePage .upgradeBonus {
  border-width: 0;
  border-color: var(--b_3);
  border-style: solid;
}

.invitePage .upgradeBonus .rewardValue h2 {
  line-height: 1;
  font-size: 50px;
}

.invitePage .upgradeBonus .img {
  width: 50%;
  max-width: 150px;
}

.invitePage .upgradeBonus .img img {
  width: 100%;
}

.invitePage .upgradeBonus .hint {
  padding: 10px;
}

.myTeam .team {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  position: relative;
  -moz-box-shadow: 0px -20px 20px 0px rgba(0, 40, 14, 0.1);
  -webkit-box-shadow: 0px -20px 20px 0px rgba(0, 40, 14, 0.1);
  box-shadow: 5px 0 10px 0px rgba(0, 40, 14, 0.1);
}

.myTeam .team .rankIcon {
  width: 40px;
  margin: 0 20px 0 0;
}

.myTeam .team .rankIcon img {
  width: 100%;
}

.myTeam .team .more_Dots {
  color: var(--black1);
}

.myTeam .team .standings > div p {
  font-size: 22px;
  font-weight: 700;
  color: var(--black1);
}

.myTeam .team .standings > div span {
  font-size: 14px;
  color: var(--black1);
  opacity: 0.8;
}

.listdetails .rankAttribute {
  margin: 0 0 10px;
}

.listdetails .rankAttribute .icon {
  width: 40px;
}

.listdetails .rankAttribute .icon img {
  width: 100%;
}

.listdetails .rankAttribute .explain {
  margin: 0 0 0 5px;
  padding: 0 0 0 5px;
}

.listdetails .rankAttribute .explain h4 {
  font-size: 18px;
}

.listdetails .listItem {
  min-height: 320px;
}

.listdetails .listItem ul > li {
  margin: 0 0 10px;
  padding: 10px;
  background-color: var(--a_5);
}

.listdetails .listItem ul > li:nth-child(odd) {
  background-color: var(--a_5);
}

.listdetails .listItem ul > li:nth-child(even) {
  background-color: var(--b_5);
}

.listdetails .listItem ul > li.skeleton {
  background-color: var(--black5);
}

.listdetails .listItem ul > li .bar li {
  background-color: #fff;
}

.listdetails .listItem ul > li.noData {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: transparent;
}

.listdetails .listItem ul > li .name p {
  font-size: 16px;
  color: var(--black1);
}

.listdetails .listItem ul > li .name span,
.listdetails .listItem ul > li .benefit span {
  font-size: 14px;
  color: var(--black2);
}

.listdetails .listItem ul > li .a p {
  font-size: 18px;
  color: var(--b_1);
}

.listdetails .listItem ul > li .na p {
  font-size: 18px;
  color: var(--black2);
}

.listdetails .loadMore {
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  color: var(--r_0);
  text-align: center;
}

div.fixedArea {
  background-color: #fff;
}

div.fixedArea .explain {
  padding: 10px 20px 0 20px;
}

div.fixedArea .actionBar {
  background-color: #fff;
}

#QRCLogo {
  display: none;
}

#invitationQRCode canvas {
  margin: 0 auto;
  display: block;
}

.copiedSuccessful,
.copiedSuccessful .successTip {
  margin: 0 0 20px;
}

.copiedSuccessful .inviteUrl {
  opacity: 0.5;
}

.copiedSuccessful .inviteUrl p {
  font-size: 16px;
}

.promptCont .okCancelBtn {
  background-color: var(--r_1);
}
</style>
