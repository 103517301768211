import { render, staticRenderFns } from "./invite.vue?vue&type=template&id=a7061916&scoped=true"
import script from "./invite.vue?vue&type=script&lang=js"
export * from "./invite.vue?vue&type=script&lang=js"
import style0 from "./invite.vue?vue&type=style&index=0&id=a7061916&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7061916",
  null
  
)

export default component.exports